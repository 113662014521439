import * as React from 'react';

import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '~/utils/cn';

const badgeVariants = cva(
  'inline-flex items-center rounded-full border font-medium transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2',
  {
    variants: {
      variant: {
        default: 'border-transparent bg-primary text-primary-foreground',
        secondary:
          'border border-neutral-250 bg-secondary text-secondary-foreground',
        destructive:
          'border-transparent bg-destructive text-destructive-foreground',
        outline: 'text-foreground',
        solid: 'border-neutral-250 border bg-neutral-0 text-neutral-600',
        important: 'bg-yellow-50 border border-yellow-300 text-yellow-900',
        blue: 'bg-blue-50 border border-blue-300 text-blue-900',
      },
      size: {
        default: 'px-2.5 py-0.5 text-xs',
        lg: 'px-4 py-2 text-sm',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
);

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, size, ...props }: BadgeProps) {
  return (
    <div
      className={cn(badgeVariants({ variant, size }), className)}
      {...props}
    />
  );
}

export { Badge, badgeVariants };
